import { combineReducers } from "redux";
import group from "./group";
import common from "./common";

const rootReducer = combineReducers({
  group,
  common,
});

export default rootReducer;
