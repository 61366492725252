import React, { useState } from "react";
import "../App.scss";
import { Button, Modal } from "react-bootstrap";
type SwitchProps = {
  checked: boolean;
  onChange?: (flag: boolean) => void;
};
const SwitchModal: React.FC<SwitchProps> = ({
  checked,
  onChange = (flag: boolean) => {},
}) => {
  return (
    <label className="switch">
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => {
          onChange(e.target.checked);
        }}
      />
      <span className="slider round"></span>
    </label>
  );
};

export default SwitchModal;
