export const SET_GROUPS = "SET_GROUPS";
export const SET_SELECTED_GROUP_INDEX = "SET_SELECTED_GROUP_INDEX";

export type GroupState = {
  groups: any;
  selectedGroupIndex: number;
};

export type SetGroups = {
  type: typeof SET_GROUPS;
  payload: any[];
};

export type SetSelectedGroupIndex = {
  type: typeof SET_SELECTED_GROUP_INDEX;
  payload: number;
};
