export const SET_MAX_SHOW_COUNT = "SET_MAX_SHOW_COUNT";
export const SET_SELECTED_THEME = "SET_SELECTED_THEME";
export const SET_FULL_SCREEN_MODE = "SET_FULL_SCREEN_MODE";
export const SET_TOKEN = "SET_TOKEN";
export const SET_SOUND = "SET_SOUND";
export const SET_MUSIC = "SET_MUSIC";

export type CommonState = {
  maxShowCount: number;
  selectedTheme: any;
  fullScreenMode: boolean;
  token: string;
  sound: boolean;
  music: boolean;
};

export type SetMaxShowCount = {
  type: typeof SET_MAX_SHOW_COUNT;
  payload: number;
};

export type SetSelectedThemes = {
  type: typeof SET_SELECTED_THEME;
  payload: any;
};

export type SetFullScreenMode = {
  type: typeof SET_FULL_SCREEN_MODE;
  payload: any;
};

export type SetToken = {
  type: typeof SET_TOKEN;
  payload: any;
};

export type SetSound = {
  type: typeof SET_SOUND;
  payload: boolean;
};

export type SetMusic = {
  type: typeof SET_MUSIC;
  payload: boolean;
};
