import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./App.scss";
import Groups from "./screens/Groups";
import Main from "./screens/Main";
import { CommonState } from "./store/types/common";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { MIN_SCALE, PLAY, STOP } from "./utils/constants";
import { setFullScreenMode, setSound, setMusic } from "./store/actions/common";
import useSound from "use-sound";
//free sounds
import clickSound from "./assets/audio/free/click.mp3";
import nameSound0 from "./assets/audio/free/sound0.mp3";
import bgSound0 from "./assets/audio/free/music0.mp3";

//pro sounds
import nameSound1 from "./assets/audio/pro/sound1.mp3";
import bgSound1 from "./assets/audio/pro/music1.mp3";

import nameSound2 from "./assets/audio/pro/sound2.mp3";
import bgSound2 from "./assets/audio/pro/music2.mp3";

import nameSound3 from "./assets/audio/pro/sound3.mp3";
import bgSound3 from "./assets/audio/pro/music3.mp3";

import nameSound4 from "./assets/audio/pro/sound4.mp3";
import bgSound4 from "./assets/audio/pro/music4.mp3";

import nameSound5 from "./assets/audio/pro/sound5.mp3";
import bgSound5 from "./assets/audio/pro/music5.mp3";

import nameSound6 from "./assets/audio/pro/sound6.mp3";
import bgSound6 from "./assets/audio/pro/music6.mp3";

const Test: React.FC<any> = ({}) => {
  return <div>Test</div>;
};

const App: React.FC<unknown> = () => {
  const { fullScreenMode, sound, music, selectedTheme } = useSelector(
    (state: { common: CommonState }) => state.common
  );
  const [screen, setScreen] = useState("main");
  const [isIos, setIsIos] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [playClickSound] = useSound(clickSound);
  const [playBgSound0, { stop: stop0 }] = useSound(bgSound0, { loop: true });
  const [playBgSound1, { stop: stop1 }] = useSound(bgSound1, { loop: true });
  const [playBgSound2, { stop: stop2 }] = useSound(bgSound2, { loop: true });
  const [playBgSound3, { stop: stop3 }] = useSound(bgSound3, { loop: true });
  const [playBgSound4, { stop: stop4 }] = useSound(bgSound4, { loop: true });
  const [playBgSound5, { stop: stop5 }] = useSound(bgSound5, { loop: true });
  const [playBgSound6, { stop: stop6 }] = useSound(bgSound6, { loop: true });
  const [playNameSound0] = useSound(nameSound0);
  const [playNameSound1] = useSound(nameSound1);
  const [playNameSound2] = useSound(nameSound2);
  const [playNameSound3] = useSound(nameSound3);
  const [playNameSound4] = useSound(nameSound4);
  const [playNameSound5] = useSound(nameSound5);
  const [playNameSound6] = useSound(nameSound6);
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: (window.innerHeight * 16) / 9,
    fontSize:
      Math.floor(Math.hypot(window.innerWidth, window.innerHeight) / 10) + "%",
  });

  const playNameSound = (id: number) => {
    switch (id) {
      case 0:
        playNameSound0();
        break;
      case 1:
        playNameSound1();
        break;
      case 2:
        playNameSound2();
        break;
      case 3:
        playNameSound3();
        break;
      case 4:
        playNameSound4();
        break;
      case 5:
        playNameSound5();
        break;
      case 6:
        playNameSound6();
        break;
      default:
        playNameSound0();
    }
  };

  const playBgSound = (id: number) => {
    switch (id) {
      case 0:
        playBgSound0();
        break;
      case 1:
        playBgSound1();
        break;
      case 2:
        playBgSound2();
        break;
      case 3:
        playBgSound3();
        break;
      case 4:
        playBgSound4();
        break;
      case 5:
        playBgSound5();
        break;
      case 6:
        playBgSound6();
        break;
      default:
        playBgSound0();
    }
  };

  const stopBgSound = (id: number) => {
    switch (id) {
      case 0:
        stop0();
        break;
      case 1:
        stop1();
        break;
      case 2:
        stop2();
        break;
      case 3:
        stop3();
        break;
      case 4:
        stop4();
        break;
      case 5:
        stop5();
        break;
      case 6:
        stop6();
        break;
      default:
        stop0();
    }
  };

  function activateFullscreen(element: any) {
    if (element.requestFullscreen) {
      element.requestFullscreen(); // W3C spec
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen(); // Firefox
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen(); // Safari
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen(); // IE/Edge
    }
  }

  function deactivateFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if ((document as any).mozCancelFullScreen) {
      (document as any).mozCancelFullScreen();
    } else if ((document as any).webkitExitFullscreen) {
      (document as any).webkitExitFullscreen();
    }
  }

  const calculateDimentions = () => {
    let width = window.innerWidth;
    let height = window.innerHeight;
    let windowWidth = window.innerWidth;
    let windowHeight = window.innerHeight;

    if (windowWidth < MIN_SCALE.width || windowHeight < MIN_SCALE.height) {
      windowWidth = width = MIN_SCALE.width;
      windowHeight = height = MIN_SCALE.height;
    }

    let calculatedWidth = (windowHeight * 16) / 9;
    let calculatedHeight = (windowWidth * 9) / 16;

    if (windowWidth < calculatedWidth) {
      height = calculatedHeight;
    } else if (windowHeight < calculatedHeight) {
      width = calculatedWidth;
    }
    return {
      height: height - 100,
      width: width - 100,
      fontSize: "inherit",
    };
  };

  useEffect(() => {
    function handleResize() {
      const dimensions = calculateDimentions();
      setDimensions(dimensions);
    }
    setTimeout(() => {
      handleResize();
    }, 10);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (music) {
      bgSoundHandler(PLAY);
    } else {
      bgSoundHandler(STOP);
    }
  }, [music]);

  useEffect(() => {
    if (music) {
      bgSoundHandler(PLAY);
    }
  }, [selectedTheme]);

  const resetAllSounds = () => {
    dispatch(setMusic(false));
    dispatch(setSound(false));
  };

  useEffect(() => {
    window.addEventListener("beforeunload", resetAllSounds);
    return () => {
      window.removeEventListener("beforeunload", resetAllSounds);
    };
  }, []);

  const bgSoundHandler = (flag: string) => {
    if (flag === PLAY) {
      playBgSound(selectedTheme.id);
    } else if (flag === STOP) {
      stopBgSound(selectedTheme.id);
    }
  };

  const fullScreenExitHandler = () => {
    {
      if (
        !(document as any).webkitIsFullScreen &&
        !(document as any).mozFullScreen &&
        !(document as any).msFullscreenElement
      ) {
        dispatch(setFullScreenMode(false));
      }
    }
  };

  useEffect(() => {
    if (fullScreenMode) {
      activateFullscreen(document.documentElement);
      if (document.addEventListener) {
        document.addEventListener(
          "fullscreenchange",
          fullScreenExitHandler,
          false
        );
        document.addEventListener(
          "mozfullscreenchange",
          fullScreenExitHandler,
          false
        );
        document.addEventListener(
          "MSFullscreenChange",
          fullScreenExitHandler,
          false
        );
        document.addEventListener(
          "webkitfullscreenchange",
          fullScreenExitHandler,
          false
        );
      }
    } else {
      if (document.fullscreenElement) {
        deactivateFullscreen();
        document.removeEventListener("fullscreenchange", () => {}, false);
        document.removeEventListener("mozfullscreenchange", () => {}, false);
        document.removeEventListener("MSFullscreenChange", () => {}, false);
        document.removeEventListener("webkitfullscreenchange", () => {}, false);
      }
    }
  }, [fullScreenMode]);

  const isIpadOS = () => {
    return (
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      /MacIntel/.test(navigator.platform)
    );
  };

  const isIOS = () => {
    const ua = navigator.userAgent;
    return /iPad|iPhone|iPod/.test(ua);
  };

  const isSafari = () => {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  };

  useEffect(() => {
    if (isIpadOS() || isIOS() || isSafari()) {
      setIsIos(true);
    }
  }, []);

  return (
    <>
      <div className="main-wrapper">
        <div
          className={`screen-container ${fullScreenMode ? "full-screen" : ""}`}
          style={{
            width: dimensions.width,
            height: dimensions.height,
            fontSize: dimensions.fontSize,
          }}
        >
          {screen === "main" && (
            <Main
              gotoScreen={setScreen}
              playClickSound={playClickSound}
              playNameSound={playNameSound}
              playBgSound={playBgSound}
              stopBgSound={stopBgSound}
              bgSoundHandler={bgSoundHandler}
              isIos={isIos}
            ></Main>
          )}
          {screen === "groups" && <Groups gotoScreen={setScreen}></Groups>}
        </div>
      </div>
      <Outlet />
      <BrowserRouter>
        <Routes>
          <Route path="/test_path" element={<Test />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
