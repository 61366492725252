import {
  SetGroups,
  SetSelectedGroupIndex,
  SET_GROUPS,
  SET_SELECTED_GROUP_INDEX,
} from "../types/group";

export const setGroups = (payload: any[]): SetGroups => ({
  type: SET_GROUPS,
  payload,
});

export const setSelectedGroupIndex = (
  payload: number
): SetSelectedGroupIndex => ({
  type: SET_SELECTED_GROUP_INDEX,
  payload,
});
