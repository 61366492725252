import React, { useCallback, useEffect, useState } from "react";
import "../App.scss";
import Group from "../components/Group";
import Member from "../components/Member";
import { ReactComponent as AddGroup } from "../assets/controls/Add-Group.svg";
import { ReactComponent as AddMember } from "../assets/controls/Add-Member.svg";
import RNSModal from "../components/RNSModal";
import Switch from "../components/Switch";
import { useDispatch, useSelector } from "react-redux";
import { GroupState } from "../store/types/group";
import { setGroups, setSelectedGroupIndex } from "../store/actions/group";
import { CommonState } from "../store/types/common";
import { setMaxShowCount } from "../store/actions/common";
import MessageModal from "../components/MessageModal";
import {
  GROUP_MEMBER,
  GROUP_NAME,
  MAX_NAME_LENGTH,
  RNS_MODAL_GROUP_MEMBERS_BODY_TEXT,
  RNS_MODAL_GROUP_NAME_BODY_TEXT,
} from "../utils/constants";
type GroupsProps = {
  gotoScreen?: (val: string) => void;
};
const Groups: React.FC<GroupsProps> = ({ gotoScreen = () => {} }) => {
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [showMemberModal, setShowMemberModal] = useState(false);
  const [editGroups, setEditGroups] = useState(false);
  const [editMembers, setEditMembers] = useState(false);
  const dispatch = useDispatch();
  const { groups, selectedGroupIndex } = useSelector(
    (state: { group: GroupState }) => state.group
  );

  const gotoMain = () => {
    gotoScreen("main");
  };

  const handleChange = (checked: boolean, index: number) => {
    groups[selectedGroupIndex].members[index].selected = checked;
    dispatch(setGroups([...groups]));
  };

  const displayCountHandler = (checked: boolean) => {
    groups[selectedGroupIndex].displayCount = checked;
    dispatch(setGroups([...groups]));
  };

  const add = () => {
    groups[selectedGroupIndex].maxShowCount =
      groups[selectedGroupIndex].maxShowCount + 1;
    dispatch(setGroups([...groups]));
  };

  const subtract = () => {
    if (groups[selectedGroupIndex].maxShowCount > 1) {
      groups[selectedGroupIndex].maxShowCount =
        groups[selectedGroupIndex].maxShowCount - 1;
      dispatch(setGroups([...groups]));
    }
  };

  const addGroup = (groupName: string) => {
    if (groupName !== "") {
      let namesArr: Array<string> = groupName.split(",");
      namesArr.forEach((name: string) => {
        name = name.trim();
        if (name && name !== "") {
          let obj = {
            name: name.trim(),
            members: [],
            maxShowCount: 1,
          };
          groups.push(obj);
        }
      });
    }
    dispatch(setGroups([...groups]));
    if (groups.length > 0) {
      //select last group in list
      dispatch(setSelectedGroupIndex(groups.length - 1));
    }
  };

  const addMember = (memberName: string) => {
    if (memberName !== "") {
      let namesArr: Array<string> = memberName.split(",");
      namesArr.forEach((name: string) => {
        name = name.trim();
        if (name && name !== "") {
          let obj = {
            name: name.trim(),
            selected: true,
          };
          groups[selectedGroupIndex].members.push(obj);
        }
      });
      dispatch(setGroups([...groups]));
    }
  };

  return (
    <div className="groups-screen container-fluid theme-0 d-flex flex-column justify-content-start">
      <div className="row">
        <div className="col-12">
          <button
            className="button-back"
            onClick={() => {
              gotoMain();
            }}
          >
            &#8249;Done
          </button>
        </div>
      </div>
      <div className="row flex-grow-1">
        <div className="col-4 section-left d-flex flex-column">
          <h3>
            Groups
            {!editGroups && groups.length > 0 && (
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setEditGroups(true);
                }}
              >
                Edit
              </button>
            )}
            {editGroups && groups.length > 0 && (
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setEditGroups(false);
                }}
              >
                Done Editing
              </button>
            )}
          </h3>
          <div className="groups-list flex-grow-1">
            {groups.map((group: any, index: number) => {
              return (
                <Group
                  index={index}
                  key={index}
                  name={group.name}
                  edit={editGroups}
                  members={group.members}
                  selectedGroupIndex={selectedGroupIndex}
                  setEditGroup={(groupName: string, index: number) => {
                    groups[index].name = groupName;
                    dispatch(setGroups(groups));
                  }}
                  setDeleteGroup={(index: number) => {
                    groups.splice(index, 1);
                    if (selectedGroupIndex == index) {
                      dispatch(setSelectedGroupIndex(0));
                    }
                    dispatch(setGroups(groups));
                  }}
                  setSelectedGroupIndex={(index) => {
                    dispatch(setSelectedGroupIndex(index));
                  }}
                ></Group>
              );
            })}
          </div>
        </div>
        <div className="col-8 section-right d-flex flex-column">
          <h3>
            {groups[selectedGroupIndex]?.name}
            {!editMembers && groups[selectedGroupIndex]?.members.length > 0 && (
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setEditMembers(true);
                }}
              >
                Edit
              </button>
            )}
            {editMembers && groups[selectedGroupIndex]?.members.length > 0 && (
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setEditMembers(false);
                }}
              >
                Done Editing
              </button>
            )}
          </h3>
          <div className="controls-list">
            <div className="controls-list-item">
              {groups.length > 0 ? (
                <>
                  Display names up to {groups[selectedGroupIndex]?.maxShowCount}{" "}
                  time{groups[selectedGroupIndex]?.maxShowCount > 1 ? "s" : ""}
                  <div className="button-plus-minus">
                    <button className="minus" onClick={subtract}>
                      -
                    </button>
                    <button className="plus" onClick={add}>
                      +
                    </button>
                  </div>
                </>
              ) : (
                ``
              )}
            </div>
            {groups[selectedGroupIndex]?.maxShowCount === 1 && (
              <div className="controls-list-item">
                <div className="d-flex flex-column">
                  <span>Display number of names left</span>
                  <span className="sub-text">
                    Only available when names are set to display 1 time
                  </span>
                </div>
                <div>
                  <Switch
                    onChange={(checked) => {
                      displayCountHandler(checked);
                    }}
                    checked={groups[selectedGroupIndex]?.displayCount || false}
                  ></Switch>
                </div>
              </div>
            )}
          </div>
          <div className="members-list flex-grow-1">
            {groups[selectedGroupIndex]?.members.map(
              (member: any, index: number) => {
                return (
                  <Member
                    key={index}
                    name={member.name}
                    index={index}
                    selected={member.selected}
                    edit={editMembers}
                    handleChange={handleChange}
                    setEditMember={(memberName: string, index: number) => {
                      groups[selectedGroupIndex].members[index].name =
                        memberName;
                      dispatch(setGroups([...groups]));
                    }}
                    setDeleteMember={(index: number) => {
                      groups[selectedGroupIndex].members.splice(index, 1);
                      dispatch(setGroups([...groups]));
                    }}
                  ></Member>
                );
              }
            )}
          </div>
        </div>
      </div>
      <div className="row mt-auto">
        <div className="col-4" style={{ borderRight: "1px solid #ccc" }}>
          <div className="footer py-3" style={{ textAlign: "right" }}>
            <button
              className="button-add"
              onClick={() => {
                setShowGroupModal(true);
              }}
            >
              <AddGroup></AddGroup>Add Group
            </button>
          </div>
        </div>
        <div className="col-8">
          <div className="footer py-3">
            {groups.length > 0 && (
              <button
                className="button-add"
                onClick={() => {
                  setShowMemberModal(true);
                }}
              >
                <AddMember></AddMember>Add Member
              </button>
            )}
          </div>
        </div>
      </div>
      <RNSModal
        show={showGroupModal}
        title={GROUP_NAME}
        body={`${RNS_MODAL_GROUP_NAME_BODY_TEXT} ${MAX_NAME_LENGTH}`}
        addName={addGroup}
        showHideModal={setShowGroupModal}
      ></RNSModal>
      <RNSModal
        show={showMemberModal}
        title={GROUP_MEMBER}
        body={`${RNS_MODAL_GROUP_MEMBERS_BODY_TEXT} ${MAX_NAME_LENGTH}`}
        addName={addMember}
        showHideModal={setShowMemberModal}
      ></RNSModal>
    </div>
  );
};

export default Groups;
