import {
  SetMaxShowCount,
  SetSelectedThemes,
  SET_MAX_SHOW_COUNT,
  SET_SELECTED_THEME,
  SET_FULL_SCREEN_MODE,
  SetFullScreenMode,
  SetToken,
  SET_TOKEN,
  SET_SOUND,
  SET_MUSIC,
  SetSound,
  SetMusic,
} from "../types/common";

export const setMaxShowCount = (payload: number): SetMaxShowCount => ({
  type: SET_MAX_SHOW_COUNT,
  payload,
});

export const setSelectedTheme = (payload: any): SetSelectedThemes => ({
  type: SET_SELECTED_THEME,
  payload,
});

export const setFullScreenMode = (payload: any): SetFullScreenMode => ({
  type: SET_FULL_SCREEN_MODE,
  payload,
});

export const setToken = (payload: any): SetToken => ({
  type: SET_TOKEN,
  payload,
});

export const setSound = (payload: boolean): SetSound => ({
  type: SET_SOUND,
  payload,
});

export const setMusic = (payload: boolean): SetMusic => ({
  type: SET_MUSIC,
  payload,
});
