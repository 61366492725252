import { themesList } from "../../utils/constants";
import {
  SetMaxShowCount,
  SetSelectedThemes,
  SetFullScreenMode,
  SET_MAX_SHOW_COUNT,
  SET_SELECTED_THEME,
  SET_FULL_SCREEN_MODE,
  SET_TOKEN,
  SET_SOUND,
  SET_MUSIC,
  SetToken,
  SetSound,
  SetMusic,
} from "../types/common";

const initialState = {
  maxShowCount: 1,
  selectedTheme: themesList[0],
  fullScreenMode: false,
  token: null,
  sound: false,
  music: false,
};

const commonReducer = (
  state = initialState,
  action:
    | SetMaxShowCount
    | SetSelectedThemes
    | SetFullScreenMode
    | SetToken
    | SetSound
    | SetMusic
) => {
  switch (action.type) {
    case SET_MAX_SHOW_COUNT: {
      return { ...state, maxShowCount: action.payload };
    }
    case SET_SELECTED_THEME: {
      return { ...state, selectedTheme: action.payload };
    }
    case SET_FULL_SCREEN_MODE: {
      return { ...state, fullScreenMode: action.payload };
    }
    case SET_TOKEN: {
      return { ...state, token: action.payload };
    }
    case SET_SOUND: {
      return { ...state, sound: action.payload };
    }
    case SET_MUSIC: {
      return { ...state, music: action.payload };
    }
    default:
      return state;
  }
};

export default commonReducer;
