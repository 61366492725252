import React, { useState } from "react";
import "../App.scss";
import { Button, Modal } from "react-bootstrap";
type ModalProps = {
  show: boolean;
  message: string;
  showHideModal?: (flag: boolean) => void;
};
const MessageModal: React.FC<ModalProps> = ({
  show,
  message,
  showHideModal = (flag: boolean) => {},
}) => {
  return (
    <Modal
      size="lg"
      dialogClassName="message-modal"
      show={show}
      centered
      onHide={() => {
        showHideModal(false);
      }}
    >
      <Modal.Header closeButton={false}>
        <Modal.Title>{message}</Modal.Title>
      </Modal.Header>
      <Modal.Footer className="justify-content-center">
        <Button
          variant="light"
          style={{ color: "#0d6efd", backgroundColor: "#fff" }}
          onClick={() => {
            showHideModal(false);
          }}
        >
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MessageModal;
