import { groupsList } from "../../utils/constants";
import {
  SetGroups,
  SetSelectedGroupIndex,
  SET_GROUPS,
  SET_SELECTED_GROUP_INDEX,
} from "../types/group";

const initialState = {
  groups: [...groupsList],
  selectedGroupIndex: 0,
};

const groupReducer = (
  state = initialState,
  action: SetGroups | SetSelectedGroupIndex
) => {
  switch (action.type) {
    case SET_GROUPS: {
      return { ...state, groups: action.payload };
    }
    case SET_SELECTED_GROUP_INDEX: {
      return { ...state, selectedGroupIndex: action.payload };
    }
    default:
      return state;
  }
};

export default groupReducer;
