export const REACT_APP_DROPBOX_BASE_URL: string = process.env
  .REACT_APP_DROPBOX_BASE_URL as string;
export const REACT_APP_DROPBOX_CONTENT_BASE_URL: string = process.env
  .REACT_APP_DROPBOX_CONTENT_BASE_URL as string;
export const REACT_APP_DROPBOX_REDIRECT_PATH: string = process.env
  .REACT_APP_DROPBOX_REDIRECT_PATH as string;
export const REACT_APP_RNS_BASE_URL: string = process.env
  .REACT_APP_RNS_BASE_URL as string;
export const REACT_APP_CLIENT_ID: string = process.env
  .REACT_APP_CLIENT_ID as string;
export const REACT_APP_CLIENT_SECRET: string = process.env
  .REACT_APP_CLIENT_SECRET as string;
